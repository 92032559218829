/**
 * Created BY HMSPL 
 * Common Service Class
 * Last Modified By Dharvesh on 12/09/2018
 * Last Modified Description : Added can activate method
 */
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthRouteGuard implements CanActivate {
  constructor(private router: Router) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // console.log(route.url)
    // console.log(state.url);
    
    // const id: string = route.snapshot.params.id;
    // const url: string = route.snapshot.url.join('');
    // const user = route.snapshot.data.user;
    if(localStorage.getItem('isDriveXLogin') == "true"){
      return true;
    }
    else if(localStorage.getItem('isDriveXApproval') == "true"){
      return true;
    }
    else{
      if (localStorage.getItem('userData') && localStorage.getItem('lang') && localStorage.getItem('langId')) {
        return true;
      } else {
        this.router.navigate(['/session/dealer']);
        return false;
      }
    }
  }
}
