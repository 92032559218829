
export class ServRemInput {
    DEALER_ID: number;
    BRANCH_ID: number;
    CUSTOMER_ID: string;
    VEHICLE_ID: string;
    JOB_TYPE_ID: string;
    FOLL_TY_ID: string;
    NEXT_SERV_DT: string;
    END_USER_ID: string;
    STATUS: string;
    FRAME_NO: string;
    CREATED_BY: string;
    ACTIVE: string;
}

export class FollowUp {
    DEALER_ID: number;
    BRANCH_ID: number;
    FOLLOWUP_NO: string;
    FOLLOWUP_DATE: string;
    EMPLOYEE_ID: string;
    REMARKS: string;
    LEAKAGE_ID: string;
    NEXT_FOL_DT: string;
    ROW_STATE: string;
    SERV_REM_ID: string;
    STATUS: string;
}

export class ReminderDO {
    DEALER_ID: number;
    BRANCH_ID: number;
    SERV_REM_ID: string;
    VEHICLE_ID: string;
    MODEL_ID: string;
    JOB_TYPE_ID: string;
    SALE_DATE: string;
    StatusId: number;
}

export class InputParams {
    isFollowupRpt: string;
    isAdvSrch: string;
    isSaleDate: string;
    jcType: string;
    vehCategory: string;
    fromDate: string;
    toDate: string;
    isInclSendServRem: string;
}

export class SearchReminderList {
    _inputParams: InputParams;
    DealerID: any;
    BranchID: any;
}


export class ServRemDo {
    SERV_REM_ID: string;
    REM_DATE: string;
    StatusId: number;
    SERV_MODE: string;
    JOB_TYPE: string;
    AREA_NAME: string;
    NEXT_SERV_DT: string;
    LAST_JC_DATE: any;
    NAME: string;
    MODEL_DESC: string;
    MOBILE_NO: string;
    MOBILE_NO2: string;
    PHONE_NO?: any;
    VEHICLE_ID: any;
    MAX_FOLLOWUPS: number;
    MODEL_ID: string;
    JOB_TYPE_ID: any;
    SALE_DATE: string;
    REG_NO: string;
    UNIQUE_NO: string;
    FRAME_NO: string;
    CUR_ODOM_READ: string;
    CUSTOMER_ID: string;
    CUST_TYPE: string;
    EMAIL_ADDRESS: string;
    ADDRESS: string;
    R_NO: any;
    STATUS_DESC: string;
}

export class FollowList {
    RunningNO: number;
    DEALER_ID: number;
    BRANCH_ID: number;
    SERV_REM_FOL_ID: number;
    SERV_REM_ID: number;
    FOLLOWUP_DATE: string;
    NEXT_FOL_DT: string;
    REMARKS: string;
    Employee?: any;
    EMPLOYEE_ID: string;
    FOLLOWUP_NO: string;
    ROW_STATE: string;
    STATUS: number;
    MODIFIED_BY: number;
    LEAKAGE_ID: string;
}

export class Data {
    ServRemDo: ServRemDo;
    _FollowList: FollowList[];
}




