export class StaticWarClaimTempClaimObject {
    DealerID: String;
    BranchID: String;    
    DLR_WAR_CLM_ID: String;
    LRNo : String;
    TransName: String; 
    UserId:String;
    CountryCode : String; 
    WarrantyTagList: any; 
    AscWarrantyTagList:any;   
    WarPermanentDelList:any;    
    FinYear: String;   
    Row_State: String;
    StateID: String;    
    SortedCombinedWarClamDetailsList: any;
    ASCWarrantyClaimList:any;
    WarClm:any;
    FreightAmount:any;
    SortedCombinedList :any;
}


export class ASCStaticWarClaimTempClaimObject {
    ASCWarrantyFrameList: any;
    DEALER_ID: String;    
    BranchId: String;
    CountryCode : String;
    StateID: String; 
    UserId:String;
    ASC_ID : String;
    _claim : any;
    ASCWarrantyDetails : any;
    Status : string;
    ClaimNo : any;
}