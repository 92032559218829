

    export class APSGrnProcess {
        tvsInvoiceNumber?: any;
        vendorInvoiceNo: number;
        newInvoiceNo:any;
        invoiceId:number;
        invoiceAmt:number;
        vendorInvoiceDate: any;
        apsCode:any;
        vendorSLCode:any;
        grnCreatedDate: Date;
        grnNo: number;
        grnDate: any;
        status: number;
        locationId: number;
        roundOff: number;
        transName: string;
        truckNo?: any;
        transRegNo: string;
        driverName: string;
        companyId: number;
        companyName: string;
        shippingCost?: any;
        otherCost?: any;
        vendorId: number;
        vendorName: string;
        freightAmount: number;
        discount: number;
        headerDiscount: number;
        tvsInvoiceDate?: any;
        isAcc: boolean;
        finYear:number;
        totalAmount:number; 
        WAREHOUSE_ID: number;
        WAREHOUSE_NAME: string;
        LR_NUMBER:string;
    }

    export class PartList {
        partId: number;
        partNo: string;
        receivedQty: number;
        unitPrice: number;
        discountAmount: number;
        locationId: number;
        rackId: number;
        damagedQty: number;
        unitCost: number;
        otherTaxAmount: number;
        taxCGST: number;
        taxSGST: number;
        taxIGST: number;
        taxtUTGST: number;
        hsnCode: string;
        taxable: boolean;
        partDesc: string;
        invoiceQty: number;
        priceUnit: number;
        otherTaxUnit: number;
        unitValue: number;
        totalvalue: number;
        manufacturerId: number;
        manufacturerName: string;
        poNo: number;       
        poDate: Date;
        uomId: string;
        uomName: string;
        tax: number;
    }

    export class APSGRNRootObject {
        statusCode: number;
        statusMessage: string;
        apsGrnProcess: APSGrnProcess;
        partList: PartList[];
    }
export class vendorListObj{
    dealerId:number;
}


