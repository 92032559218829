
    export class BasicInfo {
        dealerId: number;
        enquaryNumber: number;
        enquaryId: number;
        enquaryDate: Date;
        vehicleBrand: string;
        vehicleBrandId: number;
        vehicleModel: string;
        vehicleModelId: string;
        yearModel: number;
        vehicleId: number;
        registerNumber: string;
        kmsDriven: number;
        frameNumber: string;
        autoGenerateJobCode: number;
        jobCardNumber: number;
        jobStartTime: Date;
        jobEndTime: Date;
        mechanicId:number;
        approvedBy: string;
    }

    export class JobDescriptionList {
        jobDescriptionId: number;
        jobDescriptionName: string;
        jobCategoryId: number;
    }

    export class JobCategoryList {
        jobCategoryId: number;
        jobCategoryName: string;
        jobDescriptionList: JobDescriptionList[];
    }

    export class ServiceShop {
        serviceId: number;
        serviceShopName: string;
        serviceShopAddress: string;
        serviceShopPlace: string;
        isApproved:any;
        inspectorName:any;
        inspectionDate:any;
    }
    export class FreeSerWty {
        freeService: number;
        warranty: number;
        kiloMeterUpTo: number;
    }
    export class RefurbishmentRootObject {
        basicInfo: BasicInfo;
        jobCategoryList: JobCategoryList[];
        serviceShop: ServiceShop[];
        procurementCost: number;
    }


