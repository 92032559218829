/**
 * Created BY HMSPL 
 * Service AMC Service Class
 * Last Modified By Balaji on 03/08/2018
 * Last Modified Description : Created Service AMC related services
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RequestService } from 'src/app/api-services/request.service';

@Injectable({
    providedIn: 'root'
})
export class ServiceAmcService {
    constructor(private httpClient: HttpClient) {
    }
    /**
     * Get Service AMC List
     * @param {Object} params
     * return Object
     */
    getServiceAmcList(params) {
        const resData = RequestService.getApiURLReq('/AMC/SearchAMCDetails');
        return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
    }
    /**
     * Get Vehicle Details Based On FrameNo
     * @param {Object} params
     * return Object
     */
    getVehicleDetailsBasedOnFrameNo(params) {
        const resData = RequestService.getApiURLReq('/AMC/GetVehicleByFrameRegNoServiceAMC');
        return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
    }
    /**
     * Calculate Tax
     * @param {Object} params
     * return Object
     */
    calculateTax(params) {
        const resData = RequestService.getApiURLReq('/AMC/CalculateAMCTax');
        return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
    }
    /**
      * Close AMC
      * @param {Object} params
      * return Object
    */
    closeAMC(params) {
        const resData = RequestService.getApiURLReq('/AMC/CloseServiceAMC');
        return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
    }
    /**
     * Invoice Print
     * @param {Object} params
     * return Object
     */
    invoicePrint(params) {
        const resData = RequestService.getApiURLReq('/AMC/ServiceAMCInvPrint');
        return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
    }
    /**
     * Get amc details based on AMC ID
     * @param {Object} params
     * return Object
     */
    getAmcDetailsByAmcId(params) {
        const resData = RequestService.getApiURLReq('/AMC/PopulateAMCVehicleDetails');
        return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
    }
    /**
     * Get AMC Types
     * @param {Object} params
     * return Object
     */
    getAMCTypes(params) {
        const resData = RequestService.getApiURLReq('/Service/loadAMCType');
        return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
    }
    /**
     * Save AMC
     * @param {Object} params
     * return Object
     */
    saveServiceAMC(request) {
        const resData = RequestService.postApiURLReq('/AMC/SaveServiceAMC');
        return this.httpClient.post(resData.url, request, { headers: resData.headers });
    }
}
