/**
 * Created BY HMSPL 
 * Warranty Claim Service Class
 * Last Modified By Balaji on 23/10/2018
 * Last Modified Description : Created warranty claim service
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RequestService } from 'src/app/api-services/request.service';

@Injectable({
    providedIn: 'root'
})
export class WarrantyClaimService {
    constructor(private httpClient: HttpClient) {
    }
    /**
     * Get warranty claim List
     * @param {Object} params
     * return Object
     */
    getWarrantyClaimList(params) {
        const resData = RequestService.getApiURLReq('/ServiceClaims/SearchWarrantyClaims');
        return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
    }


    CheckLRDetails(params) {
        const resData = RequestService.getApiURLReq('/ServiceClaims/CheckLRDetails');
        return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
    }

    /**
     * Print warranty claim
     * @param {Object} params
     * return Object
     */
    printWarrantyClaim(params) {
        const resData = RequestService.getApiURLReq('/ServiceClaims/PrintWarranty');
        return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
    }
    /**
     * Print Battery warranty claim
     * @param {Object} params
     * return Object
     */
    printBatteryClaim(params) {
        const resData = RequestService.getApiURLReq('/ServiceClaims/PrintBatteryClaim');
        return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
    }
    /**
     * Invoice print claim
     * @param {Object} params
     * return Object
     */
    invoiceClaim(params) {
        const resData = RequestService.getApiURLReq('/ServiceClaims/PrintInvoice');
        return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
    }
    /**
     * Get warranty claim details
     * @param {Object} params
     * return Object
     */
    getWarrantyClaimDetails(params) {
        const resData = RequestService.getApiURLReq('/ServiceClaims/CreateWarrantyClaim');
        return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
    }
    /**
     * Get TVS warranty claim details
     * @param {Object} params
     * return Object
     */
    getTVSWarrantyClaimDetails(params) {
        const resData = RequestService.getApiURLReq('/ServiceClaims/CreateClaimInTVS');
        return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
    }
    /**
    * Print warranty tag
    * @param {Object} request
    * return Object
    */
    printWarrantyTag(params) {
        const resData = RequestService.postApiURLReq('/ServiceClaims/WarrantyTagPrint');
        return this.httpClient.post(resData.url, params, { headers: resData.headers });
    }
    //Added by V.Dineshkumar on 08-05-2019
    /**
    * Download warranty claim
    * @param {Object} params
    * return Object
    */
    downloadClaim(params) {
        const resData = RequestService.getApiURLReq('/ServiceClaims/DownloadWarrantyClaim');
        return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
    }
    //End by V.Dineshkumar on 08-05-2019
    /**
     * Delete warranty claim
     * @param {Object} request
     * return Object
    */
    deleteWarrantyClaim(params) {
        const resData = RequestService.postApiURLReq('/ServiceClaims/DeleteWarrantyLineItems');
        return this.httpClient.post(resData.url, params, { headers: resData.headers });
    }
    /**
     * Save warranty claim
     * @param {Object} request
     * return Object
    */
    saveWarrantyClaim(params) {
        const resData = RequestService.postApiURLReq('/ServiceClaims/SaveWarrantyClaim');
        return this.httpClient.post(resData.url, params, { headers: resData.headers });
    }
    /**
     * Upload warranty claim
     * @param {Object} request
     * return Object
    */
    uploadWarrantyClaim(params) {
        const resData = RequestService.postApiURLReq('/ServiceClaims/UploadWarrantyClaim');
        return this.httpClient.post(resData.url, params, { headers: resData.headers });
    }
    /**
     * frieght amount tax
     * @param {Object} request
     * return Object
    */
    getFrieghtAmtTax(params) {
        const resData = RequestService.getApiURLReq('/ServiceClaims/FrieghtAmtTax');
        return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
    }


    /**
    * LR Details
    * @param {Object} request
    * return Object
   */
    SaveLRDetails(params) {
        const resData = RequestService.postApiURLReq('/ServiceClaims/SaveLRDetails');
        return this.httpClient.post(resData.url, params, { headers: resData.headers });
    }

    /**
         * Upload warranty claim
         * @param {Object} request
         * return Object
        */
    ADDlrWarConfirm(params) {
        const resData = RequestService.postApiURLReq('/ServiceClaims/ADDlrWarConfirm');
        return this.httpClient.post(resData.url, params, { headers: resData.headers });
    }
}
