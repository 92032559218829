/**
 * Created BY HMSPL 
 * ASC FSC claim Service Class
 * Last Modified By Balaji on 23/10/2018
 * Last Modified Description : Created ASC FSC claim service
 */

 import { Injectable } from '@angular/core';
 import { HttpClient } from '@angular/common/http';
 import { RequestService } from 'src/app/api-services/request.service';
 
 
 @Injectable({
     providedIn: 'root'
 })
 export class ASCFSCClaimService {
     constructor(private httpClient: HttpClient) {
     }
     /**
      * Get ASC FSC claim List
      * @param {Object} params
      * return Object
     */
     getASCFSCClaimList(params) {
         const resData = RequestService.getApiURLReq('/ServiceClaims/ASCFSCSearch');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
     /**
      * Get ASC FSC claim by id
      * @param {Object} params
      * return Object
     */
     getASCFSCClaimDetailsById(params) {
         const resData = RequestService.getApiURLReq('/ServiceClaims/ViewASCFSCclaim');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
     /**
      * Get ASC Code
      * @param {Object} params
      * return Object
     */
     getASCCode(params) {
         const resData = RequestService.getApiURLReq('/ServiceClaims/CheckBlockWarrantyFSC');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
     /**
      * Get Coupon Details By FrameNo
      * @param {Object} params
      * return Object
     */
     getCouponDetailsByFrameNo(params) { 
         const resData = RequestService.getApiURLReq('/ServiceClaims/FrameASCCheck');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
     /**
      * Save ASC FSC Claim
      * @param {Object} request
      * return Object
     */
     saveASCClaim(params) { 
         const resData = RequestService.postApiURLReq('/ServiceClaims/SaveASCFSCClaim');
         return this.httpClient.post(resData.url, params, { headers: resData.headers });
     }
     /**
      * Confirm ASC FSC Claim
      * @param {Object} request
      * return Object
     */
     confirmASCClaim(params) { 
         const resData = RequestService.postApiURLReq('/ServiceClaims/ASCFSCConfirm');
         return this.httpClient.post(resData.url, params, { headers: resData.headers });
     }
     /**
      * Upload ASC FSC Claim
      * @param {Object} request
      * return Object
     */
     uploadASCClaim(params) { 
         const resData = RequestService.postApiURLReq('/ServiceClaims/ASCExcellUpload');
         return this.httpClient.post(resData.url, params, { headers: resData.headers });
     }
     /**
      * Print ASC FSC claim
      * @param {Object} params
      * return Object
     */
     printASCFSCClaim(params) {
         const resData = RequestService.getApiURLReq('/ServiceClaims/ASCFSCPrint');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
     /* Print ASC FSC claim
      * @param {Object} params
      * return Object
     */
     confirmAllADCliams(params) {
         const resData = RequestService.getApiURLReq('/ServiceClaims/ASCFSCConfirmAllAD');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
     /**
      * Get Job Type is Valid
      * @param {Object} params
      * return Object
     */
     getJobTypeValid(params) { 
         const resData = RequestService.getApiURLReq('/ServiceClaims/isValidFrameJobType');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
 
     /**
         * Delete the Claim Line item Permanently from Db
         * @param {Object} params
         * return Object
        */
     deleteAscFscClaimLineItem(params) { 
         const resData = RequestService.getApiURLReq('/ServiceClaims/DeleteAscFscClaim');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
 
     /**
      * Summary of the Claims
      * @param {Object} params
      * return Object
      */
     getCouponSummaryList(params) { 
         const resData = RequestService.postApiURLReq('/ServiceClaims/SummaryAscFscClaim');
         return this.httpClient.post(resData.url, params, { headers: resData.headers });
     }
 
 }
 