/**
 * Created BY HMSPL 
 * ASC warranty claim Service Class
 * Last Modified By Balaji on 23/10/2018
 * Last Modified Description : Created ASC warranty claim service
 */

 import { Injectable } from '@angular/core';
 import { HttpClient } from '@angular/common/http';
 import { RequestService } from 'src/app/api-services/request.service';
 
 
 @Injectable({
     providedIn: 'root'
 })
 export class ASCWarrantyClaimService {
     constructor(private httpClient: HttpClient) {
     }
     /**
      * Get ASC warranty claim List
      * @param {Object} params
      * return Object
      */
     getASCWarrantyClaimList(params) { 
         const resData = RequestService.getApiURLReq('/ServiceClaims/GetASCWarClaimList');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
     /**
      * Get ASC warranty claim details based on claim Id
      * @param {Object} params
      * return Object
      */
     getASCWarrantyClaimDetailsById(params) { 
         const resData = RequestService.getApiURLReq('/ServiceClaims/GetASCWarrantyClaimForModify');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
     /**
      * Get Complaint Details By FrameNo
      * @param {Object} params
      * return Object
      */
     getComplaintDetailsByFrameNo(params) {
         const resData = RequestService.getApiURLReq('/ServiceClaims/GetFrameNoDetailsForASCWarranty');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers }); 
     }
     /**
      * Get tax details based on item no
      * @param {Object} params
      * return Object
      */
     getTaxDetailsBasedOnItemNo(params) { 
         const resData = RequestService.getApiURLReq('/ServiceClaims/CalculatetaxASCWarrantyClaim');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers }); 
     }
     /**
      * Get all part details
      * @param params 
      */
     getAllPartDetails(params) { 
         const resData = RequestService.getApiURLReq('/ServiceClaims/GetPartSearch');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers }); 
     }
     /**
      * Get all part details
      * @param params 
      */
     getAllLabourDetails(params) { 
         const resData = RequestService.getApiURLReq('/ServiceClaims/GetLabourSearch');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers }); 
     }
     /**
      * Get all part details
      * @param params 
      */
     saveASCWarrantyClaim(params) {        
         const resData = RequestService.postApiURLReq('/ServiceClaims/SaveAscWarrantyClaim');
         return this.httpClient.post(resData.url, params, { headers: resData.headers });
     }
     /**
      * Upload ASC Warranty Claim
      * @param {Object} request
      * return Object
      */
     uploadASCWarrantyClaim(params) { 
         const resData = RequestService.postApiURLReq('/ServiceClaims/ASCWarrantyExcellUpload');
         return this.httpClient.post(resData.url, params, { headers: resData.headers });				
     }
 
     /**
     * Confirm the Claim
     * @param params 
     */
     confirmASCWarClaim(params) { 
         const resData = RequestService.postApiURLReq('/ServiceClaims/UpdateASCWarrantyClaimStatus');
         return this.httpClient.post(resData.url, params, { headers: resData.headers });
     }
     /**
     * Get Minimum warranty date
     * @param {Object} params
     * return Object
     */
      getMinWarrantyDate(params) { 
        const resData = RequestService.getApiURLReq('/ServiceClaims/GetMinDateforWarranty');
        return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
    }
 }
 