
export class JobcardSearch {
    AREA_NAME: string;
    BILL_TYPE: number;
    BRANCH_ID: number;
    CITY: string;
    CUSTOMER_ID: number;
    CUST_MOBILE: string;
    CUST_MOBILE2?: any;
    CUST_NAME: string;
    CUST_PHONE?: any;
    DEALER_ID: number;
    EMPLOYEE_ID?: any;
    EMPName: string;
    ENGINE_NO?: any;
    FEEDBACK?: any;
    Feedback_date?: any;
    FRAME_NO: string;
    JOB_CARD_DATE: any;
    JOB_CARD_ID: number;
    JOB_CARD_NO: number;
    JOB_TYPE_DESC: string;
    JOB_TYPE_ID: number;
    KILOMETERS: number;
    MECHANIC_NAME?: any;
    MODEL_DESC: string;
    MODEL_ID?: any;
    REGIS_NO: string;
    REG_NO?: any;
    SALE_DATE: Date;
    SERV_ADV_NAME?: any;
    SERV_MODE_ID: number;
    STATUS: number;
    SuperviserName: string;
    VEHICLE_ID: number;
}

export class AnswerList {
    ANSWER_ID: number;
    QUESTION_ID: number;
    ANSWER: string;
    ACTIVE: boolean;
    PSF_TYPE: number;
    USER_ANSWER?: any;
}

export class PsfQuestionAn {
    ANSWER_ID: number;
    QUESTION_ID: number;
    ANSWER: any;
    USER_ANSWER?: any;
    ACTIVE: boolean;
    PSF_TYPE: number;
    COUNTRY_ID?: any;
    QUESTION_DESCRIPTION: string;
    QUESTION_CONTROL_ID: number;
    ORDERBY_NO: number;
    AnswerList: AnswerList[];
}

export class ComplaintList {
    COMPLAINT_ID: number;
    DESCRIPTION: string;
    ComplaintGroup: string;
}

export class getPsfData {
    JobcardSearch: JobcardSearch[];
    PsfQuestionAns: PsfQuestionAn[];
    Customer?: Customer;
    complaintList: ComplaintList[];
}


export class Customer {
    GST_IN_NO?: any;
    AADHAR_NO?: any;
    STATE_ID?: any;
    CUSTOMER_ID: number;
    DEALER_ID: number;
    SALUTATION?: any;
    CUST_NAME?: any;
    SON_WIFE_DAU_OF?: any;
    DATE_OF_BIRTH?: any;
    CUST_DATE_OF_BIRTH?: any;
    YEAR_OF_BIRTH?: any;
    GENDER?: any;
    IS_PROSPECT: boolean;
    CUST_GRP_ID?: any;
    CUST_TY_ID: number;
    EMAIL_ADDRESS?: any;
    IS_EMAIL_ID_VALID: boolean;
    EMPL_TYPE?: any;
    PROFESSION_ID?: any;
    INCOME_ID?: any;
    MOBILE_NO?: any;
    EDUCATION_ID?: any;
    CUST_TAX_CAT_ID?: any;
    REF_CUST_ID?: any;
    SEND_SERV_REM: boolean;
    CUST_LIKE_TO_VST: boolean;
    HANDICAPPED: boolean;
    TVS_CUSTOMER_ID?: any;
    FOLL_TY_ID?: any;
    OLD_CUST_ID?: any;
    MARRIED: boolean;
    ANNIV_DATE?: any;
    CREDIT_CARD: boolean;
    IS_SL_CODE_GEN: boolean;
    CUST_SERV_TY_ID: boolean;
    VEH_CRED_LIM: number;
    SPR_CRED_LIM: number;
    GEN_CRED_LIM: number;
    SPR_MARGIN: number;
    SL_CODE?: any;
    REMARKS?: any;
    Comm_Language?: any;
    Rel_with_EndUser?: any;
    NUMBER_KTP?: any;
    CREATED_BY: number;
    CREATED_ON: Date;
    MODIFIED_BY?: any;
    MODIFIED_ON?: any;
    ACTIVE: boolean;
    ROW_STATE: number;
    FullAddress?: any;
    CustType: number;
    CommunicationAddress?: any;
    OfficeAddress?: any;
    ResidenceAddress?: any;
    RegistrationAddress?: any;
    CommnAddressPhone?: any;
    Mobile?: any;
    OfficePhone1?: any;
    OfficePhone2?: any;
    OfficeFaxNo?: any;
    ResidencePhone?: any;
    RegnAddressPhone?: any;
    Enduser?: any;
    CIC?: any;
    InstitutionDetails?: any;
    ContactList?: any;
    AddressList?: any;
    EndUserList?: any;
    VehicleList?: any;
    LedgerList?: any;
    LEDGER_VALUES?: any;
    CUSTOMER_TYPE?: any;
    CUSTOMER_GROUP?: any;
    PaymentDueVehicle: number;
    PaymentDueSpares: number;
    PaymentDueGeneric: number;
    CustomerType?: any;
    IsLastSale: boolean;
    GROUP?: any;
    CUST_CAT_ID: number;
    AvailableCreditLimitForVehicle: number;
    AvailableCreditLimitForSpares: number;
    AvailableCreditLimitForGeneric: number;
    OLD_VEHICLE: boolean;
    EXIST_MODEL_ID?: any;
    DLR_COUNTRY_CODE?: any;
    CONT_PERSON?: any;
    ConsumedCredit?: any;
    GEN_CRED_CON: number;
    CompleteAddress?: any;
    AREA_NAME?: any;
    CONTACT_NO?: any;
    MODLE_DESCRIPTION?: any;
    AGE: number;
    FRAME_NO?: any;
    ENGINE_NO?: any;
    SALE_DATE?: any;
    LAST_JOB_CARD_DATE?: any;
    chkSelect: boolean;
    CREDIT_LIMIT_DAYS: number;
    VENDOR_ID: number;
    EMPLOYEE_ID: number;
    SERIES?: any;
    CATEGORY?: any;
    CITY?: any;
    NAME?: any;
    BLOCK_FSC: boolean;
    BLOCK_WARRANTY: boolean;
    END_USER_ID: number;
    FromPage?: any;
    CustomerSPMargin?: any;
    PAN_NO?: any;
    CUSTOMER_PAN_COUNT?: any;
    APS_CODE?: any;
}



export class PSF {
    DEALER_ID: number;
    BRANCH_ID: number;
    CREATED_BY: string;
    JOB_CARD_ID: any;
    CUSTOMER_SUGGESTION: string;
    REMARKS: string;
    NEXT_FOLLOWUP_DATE: any;
    NEXT_FOLLOWUP_REASON_ID: string;
}

export class Service {
    DEALER_ID: number;
    BRANCH_ID: number;
    CREATED_BY: number;
    APPOINTMENT_ID: number;
    SERV_PREF: string;
    CUSTOMER_ID: number;
    END_USER_ID: number;
    JOB_TYPE_ID: number;
    APT_CAT_ID: number;
    SERV_MODE_ID: number;
    APPOINTMENT_BY?: any;
    REGIS_NO: string;
    VEHICLE_ID: number;
    APPOINT_DATE: string;
    CUST_ARRIVAL_DATE: string;
    STATUS: number;
    ACTIVE: string;
}

export class QusetionAnsList {
    QUESTION_ID: number;
    ANSWER_ID: number;
    ANSWER: string;
}

export class CreatePSF {
    PSF: PSF;
    _service: Service;
    QusetionAnsList: QusetionAnsList[];
}







