

    export class PartList {
        partId: number;
        partNo: string;
        partDescription: string;
        itemTaxCatId: number;
        itemTypeId: number;
        matTypeId: number;
        invoiceQty: number;
        receivedQty: number;
        damagedQty: number;
        MRP: number;
        unitPrice: number;
        unitPriceQty: number;
        inputTaxValue: number;
        inputTaxValueQty: number;
        discountAmount: number;
        unitCost: number;
        locationId: number;
        rackId: number;
        otherTaxAmount: number;
        taxable: boolean;
        unitValue: number;
        totalvalue: number;
        manufacturerId: number;
        manufacturerDescription: string;
        uomId: string;
        uomDescription: string;
        taxCGST: number;
        taxSGST: number;
        taxIGST: number;
        taxUTGST: number;
        hsnCode: string;
    }

    export class AccessoryGRNRootObject {
        dealerId: number;
        branchId: number;
        companyId: number;
        invoiceId: number;
        invoiceNo: number;
        invoiceDate: any;
        spareGRNId: number;
        spareGRNNo: number;
        spareGRNDate: any;
        locationId: number;
        amdID: any;
        vendorId: number;
        amd_APS_CODE: number;
        totalAmount: number;
        roundOff: number;
        freightAmount: number;
        discount: number;
        status: number;
        userId: number;
        countryCode: string;
        financialYear: number;
        partList: PartList[];
    }



