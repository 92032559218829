/**
 * Created BY HMSPL
 * refurbishment Service Class
 * Last Modified By selva on 27/08/2019
 * Last Modified Description : Created refurbishment service
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RequestService } from 'src/app/api-services/request.service';


@Injectable({
    providedIn: 'root'
})
export class RefurbishmentService {
    constructor(private httpClient: HttpClient) {
    }
    /**
     * UVD POST
     * @param {Object} params
     * return Object
    */
    post(params, url) {
        const resData = RequestService.uvdReq(url);
        return this.httpClient.post(resData.url, params,{headers:resData.headers});
    }
    /**
     * UVD GET
     * @param {Object} request
     * return Object
     */
    get(param,url) {
        const resData = RequestService.uvdReq(url);
        return this.httpClient.get(resData.url, { params: param, headers: resData.headers });
    }
        /**
     * UVD PUT
     * @param {Object} request
     * return Object
     */
    put(param,url) {
        const resData = RequestService.uvdReq(url);
        return this.httpClient.put(resData.url,param);
    }

    delete(param, url) {
        const resData = RequestService.uvdReq(url);
        return this.httpClient.delete(resData.url);
    }
}
