export class deliveryGrnCreate {
    GRN_NO: any;
    GRN_DATE: any;
    JOB_CARD_NO: any;
    DC_NO: any;
    VENDOR_NAME: any;
    VENDOR_ADDRESS: any;
    DC_VALUE: any;
    DC_GRNVALUE: any;
}
export class deliveryCreate {
    DC_NO: any;
    DC_DATE: any;
    VENDOR_NAME: any;
    VENDOR_ADDRESS: any;
    JOB_CARD_NO: any;
    DC_VALUE: any;
    DC_STATUS: any;
    E_START_DATE: any;
    E_DELIVERY_DATE: any;
    START_DATE: any;
    END_DATE: any;
    TOTAL_PARTS: number;
}